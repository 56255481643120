/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
import {
  pageCache,
  imageCache,
  staticResourceCache,
  googleFontsCache
} from "workbox-recipes";

import { registerRoute } from "workbox-routing";
import { NetworkFirst, StaleWhileRevalidate } from "workbox-strategies";
import { precacheAndRoute } from "workbox-precaching";

declare const self: ServiceWorkerGlobalScope;

registerRoute(
  ({ url }) => url.origin.includes("api/v"),
  new NetworkFirst({ cacheName: "api-responses" })
);

registerRoute(
  ({ url }) => url.origin.includes("https://api.mapbox.com"),
  new StaleWhileRevalidate({ cacheName: "map-responses" })
);

pageCache();

googleFontsCache();

staticResourceCache();

imageCache();

precacheAndRoute(self.__WB_MANIFEST);